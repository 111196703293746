<template>
<div>

  <div id="main" class="site-main">

  <div id="main-content" class="main-content">

  <mainslider/>
  <div id="page-title" class="page-title-block page-title-style-1 has-background-image" style="background-image: url(/assets/backgrounds/title/06.jpg);"><div class="container"><div class="page-title-icon"><div class="sc-icon sc-icon-size-medium angle-90deg sc-icon-shape-circle" style="background-color: #ffffff;border: 2px solid #ffffff;"><span class="sc-icon-half-1" style=""><span class="back-angle">&#xe770;</span></span><span class="sc-icon-half-2" style=""><span class="back-angle">&#xe770;</span></span></div></div><div class="page-title-title"><h1 style="">  Реализуемые проекты</h1></div></div></div>




  <div class="block-content">
  	<div class="container">
  		<div class="panel row">

  			<div class="panel-center col-xs-12">
  				<article id="post-11266" class="post-11266 page type-page status-publish hentry">


  					<div class="entry-content post-content">
  												<div class="vc_row wpb_row vc_row-fluid"><div class="wpb_column vc_column_container vc_col-sm-12"><div class="vc_column-inner "><div class="wpb_wrapper"><div class="clearboth"></div><div class="sc-divider sc-divider-style-1" style="margin-bottom: 55px;"><svg width="100%" height="1px"><line x1="0" x2="100%" y1="0" y2="0" stroke="" stroke-width="2" stroke-linecap="black" stroke-dasharray="11, 9"/></svg></div><div class="sc-textbox rounded-corners"  style=""><div class="sc-textbox-content" style="background-image: url(/assets/backgrounds/projects/peremena-banner.jpg);background-position: center top;"><div class="sc-icon-with-text sc-alert-box clearfix sc-icon-with-text-icon-size-big centered-box" style=""><div class="sc-icon-with-text-icon"><div class="sc-icon sc-icon-size-big angle-90deg sc-icon-shape-circle sc-simple-icon" style=""><span class="sc-icon-half-1" style="color: #ffffff;"><span class="back-angle">&#xe67e;</span></span><span class="sc-icon-half-2" style="color: #ffffff;"><span class="back-angle">&#xe67e;</span></span></div></div><div class="sc-icon-with-text-content">
  	<div class="wpb_text_column wpb_content_element  vc_custom_1450209071815" >
  		<div class="wpb_wrapper">
  			<h3><span style="color: #ffffff;">ПЕРЕМЕНА</span></h3>
  <p><span style="color: #ffffff;">Создание единого образовательного пространства для обучающихся детей на основе объединения учреждений культуры и искусства. Создание системы непрерывного образования детей в условиях развития программ внутреннего туризма на территории Российской Федерации совместно с органами власти, представителями системы образования, туриндустрии, бизнеса.</span></p>

  		</div>
  	</div>
  </div><div class="sc-alert-box-button"><a class="sc-button "  href="http://www.peremena.travel/" target="_self" style="color: #59abb7;background-color: #e3ffb4;">ОЗНАКОМИТЬСЯ</a></div><div class="clearboth"></div></div></div></div></div></div></div></div><div class="vc_row wpb_row vc_row-fluid"><div class="wpb_column vc_column_container vc_col-sm-12"><div class="vc_column-inner "><div class="wpb_wrapper">
  	<div class="wpb_text_column wpb_content_element " >
  		<div class="wpb_wrapper">

  		</div>
  	</div>
  </div></div></div></div><div class="vc_row wpb_row vc_row-fluid"><div class="wpb_column vc_column_container vc_col-sm-12"><div class="vc_column-inner "><div class="wpb_wrapper"><div class="clearboth"></div><div class="sc-divider sc-divider-style-1" style="margin-bottom: 55px;"><svg width="100%" height="1px"><line x1="0" x2="100%" y1="0" y2="0" stroke="" stroke-width="2" stroke-linecap="black" stroke-dasharray="11, 9"/></svg></div><div class="sc-textbox rounded-corners"  style=""><div class="sc-textbox-content" style="background-image: url(/assets/backgrounds/projects/bsp-banner.jpg);background-position: center top;"><div class="sc-icon-with-text sc-alert-box clearfix sc-icon-with-text-icon-size-big centered-box" style=""><div class="sc-icon-with-text-icon"><div class="sc-icon sc-icon-size-big angle-90deg sc-icon-shape-circle sc-simple-icon" style=""><span class="sc-icon-half-1" style="color: #ffffff;"><span class="back-angle">&#xe621;</span></span><span class="sc-icon-half-2" style="color: #ffffff;"><span class="back-angle">&#xe621;</span></span></div></div><div class="sc-icon-with-text-content">
  	<div class="wpb_text_column wpb_content_element  vc_custom_1450208998595" >
  		<div class="wpb_wrapper">
  			<h3><span style="color: #ffffff;">БАНК СОЦИАЛЬНЫХ ПУТЕВОК</span></h3>
  <p><span style="color: #ffffff;">Создание экономически и инфраструктурно доступной туристской среды и условий для массовых путешествий лиц старшего поколения в туристских целях. Культурно-познавательные, оздоровительные, и другие программы адаптированные под преклонный возраст.</span></p>

  		</div>
  	</div>
  </div><div class="sc-alert-box-button"><a class="sc-button "  href="http://xn--80abelycrjchuom4b.xn--p1ai/" target="_self" style="color: #59abb7;background-color: #e3ffb4;">ОЗНАКОМИТЬСЯ</a></div><div class="clearboth"></div></div></div></div></div></div></div></div></p>
  					</div><!-- .entry-content -->







  				</article><!-- #post-## -->

  			</div>


  		</div>


  	</div>
  </div>



  </div><!-- #main-content -->


</div>
</div>
</template>

<script>
import mainslider from '@/components/mainslider.vue'
export default {
name : "Projects",
components : {mainslider}

}
</script>
